import { useEffect } from 'react';

import Navbar from '../../components/Shared/Navbar/index';
import Slides from '../../components/Shared/Slides';
import Boxes from '../../components/Shared/Boxes/index';
import Slick from '../../components/Shared/Slick';

import { INT_IMAGES } from '../../data/integrations/data';
import { PROVIDERS } from '../../data/integrations/providers';
import { VOUCHERS } from '../../data/integrations/vouchers';
import { COUNTRIES } from '../../data/integrations/countries';
const Integrations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Slides title='Services Integration' data={INT_IMAGES} />
      <Slick title='International Mobile Recharging' data={PROVIDERS} />
      <Slick title='Digital Vouchers' data={VOUCHERS} />
      <Slick
  title={
    <>
      Travel eSIM (150+ country) <br />
      <span style={{ fontWeight: 'normal', fontSize: '0.6em' }}>Top Countries</span>
    </>
  }
  data={COUNTRIES}
/>


      {/* <Boxes /> */}
    </>
  );
};

export default Integrations;
